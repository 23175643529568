@tailwind base;
@tailwind components;
@tailwind utilities;

.badge {
  @apply inline-block p-1 text-center leading-5 rounded-full text-white bg-red-500;
  min-width: 1.75rem;
}

.input {
  @apply block w-full p-2 border border-gray-400 rounded focus:border-black focus:outline-none;
}

.input.has-error {
  @apply border-red-500;
}

.button {
  @apply inline-block self-center py-2 px-3 text-base transition rounded focus:outline-none disabled:cursor-wait disabled:bg-gray-200;
}

.button-purple {
  @apply button text-white bg-purple-600 hover:bg-purple-700 focus:bg-purple-700;
}

.button-gray {
  @apply button text-white bg-gray-500 hover:bg-gray-600 focus:bg-gray-600;
}

/* React Toast */
.Toastify .Toastify__toast-container {
  width: 460px;
  max-width: 100%;
  padding: 0;
}

.Toastify .Toastify__toast-container--top-right {
  top: 0.5rem;
  right: 0.5rem;
}

/* React Datepicker */
.react-datepicker__header {
  background-color: #fff;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #fff;
}
.react-datepicker__navigation--previous:focus,
.react-datepicker__navigation--next:focus {
  outline: none;
}
.react-datepicker__day--in-selecting-range {
  color: #5b21b6;
  background-color: #ddd6fe;
}
.react-datepicker__day--in-selecting-range:hover {
  background-color: #c4b5fd;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  color: #fff;
  background-color: #5b21b6;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--in-range:hover {
  background-color: #4c1d95;
}

/* React Color Picker */
.block-picker {
  border: 1px solid #d1d5db;
  box-shadow: none !important;
}

.block-picker div[title="#fff"] {
  border: 1px solid #d1d5db;
}
